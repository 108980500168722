import { useFlags } from "launchdarkly-react-client-sdk";
import config from "./config";

/**
 * We can't know, at typecheck-time, which flags exist.  As such, in order to
 * get some measure of type-safety, we need to manual define any types we expect
 * might exist. Add any new flags you want to rely on in code to this type.
 */
type Flags = Partial<{
  creditsPage: boolean;
  contractScorePage: boolean;
  discountsPage: boolean;
  globalBetaBanner: boolean;
  commitmentsPageV2: boolean;
  contractListPageV2: boolean;
}>;

// For local coding, we can override the flags we get from LaunchDarkly.
const isDev = config.environment == "development";
const flagOverrides: Flags = isDev
  ? {
      // Override LaunchDarkly flags by adding something here like:
      // creditsPage: false,
    }
  : {};

/**
 * This is a hook that overrides and wraps LaunchDarkly's `useFlags()` hook.  It
 * allows us to add type safety to `useFlags()` as well as provide local
 * overriding of flags.
 */
export const useSkywayFlags = (): Flags => {
  const remoteFlags = useFlags();
  const combinedFlags = { ...remoteFlags, ...flagOverrides };

  return combinedFlags;
};
