/* eslint-disable react-hooks/rules-of-hooks */
// TODO: this ^ lint rule is catching a true positive - we're conditionally
// calling hooks, which may cause problems.  It *doesn't* cause problems, but we
// should really be doing the safe thing.  Remove this comment and fix the
// underlying issue.  - kh 2024-12-03
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import {
  localStorageCache,
  sessionStorageCache,
} from "../modules/localStorageHelper";

const LAST_ORG_EXTERNAL_CODE_KEY = "lastOrgExternalCode";

// Get the current org ID to show on the page.  Pulls from the url first (eg
// /org/123/dashboard), then tries session storage (ie the last org we looked at
// in the current page), then tries local storage (ie the last org we looked at
// in this browser).
export function useCurrentOrgExternalCode() {
  // This allows us to inject an orgExternalCode in storybook stories.  TODO: this should
  // be provided by a context that's swapped out via a decorator.
  const fixturedOrgExternalCode: string | undefined = (window as any)[
    LAST_ORG_EXTERNAL_CODE_KEY
  ];

  const orgExternalCode =
    useOrgExternalCodeFromRoute() ??
    fixturedOrgExternalCode ??
    orgExternalCodeFromSessionStorage() ??
    orgExternalCodeFromLocalStorage();

  useEffect(() => {
    if (orgExternalCode) {
      saveCurrentOrgExternalCode(orgExternalCode);
    }
  }, [orgExternalCode]);

  return orgExternalCode;
}

// Get the current org code, but if it's not available, try to find the first
// available org code from the backend and save it.
export function useCurrentOrgExternalCodeWithFallback() {
  const orgExternalCode = useCurrentOrgExternalCode();
  const firstAvailableOrgExternalCode = useFirstAvailableOrgExternalCode();
  if (!orgExternalCode && firstAvailableOrgExternalCode) {
    saveCurrentOrgExternalCode(firstAvailableOrgExternalCode);
  }
  return orgExternalCode ?? firstAvailableOrgExternalCode;
}

// If a user just arrived on an org-specific page (/org/123/foo), and you want
// to both persist that org code (123) as the currently-selected org and also
// redirect to the non-org-specific version of the page (/foo), use this hook in
// your page component.
export function useRedirectToNonOrgRoute(route: string) {
  const navigate = useNavigate();
  const orgExternalCodeFromRoute = useOrgExternalCodeFromRoute();
  useEffect(() => {
    if (orgExternalCodeFromRoute) {
      saveCurrentOrgExternalCode(orgExternalCodeFromRoute);
      navigate(route);
    }
  }, [orgExternalCodeFromRoute, navigate, route]);
}

// Get the current org object to show on the page.  See useCurrentOrgExternalCode() for
// how this looks up the org code.  Returns a standard tanstack query response, so
// get the actual org data (or `undefined` before it's finished loading) with:
// const { data: org } = useCurrentOrg();
export function useCurrentOrg(fallback: boolean = true) {
  const orgExternalCode = fallback
    ? useCurrentOrgExternalCodeWithFallback()
    : useCurrentOrgExternalCode();
  const queryResult = useQuery({
    queryFn: () => api.getOrganization({ externalCode: orgExternalCode ?? "" }),
    queryKey: ["org", orgExternalCode],
    enabled: !!orgExternalCode,
    retry: false,
  });
  if (queryResult.error) {
    if (
      queryResult.error instanceof AxiosError &&
      queryResult.error.response?.status === 403
    ) {
      // This is a 403 error, which probably means the user doesn't have access
      // to this org.  In this case, clear the orgExternalCode cache so we don't keep
      // trying to load this org.
      clearCurrentOrgExternalCode();
    }
  }
  return queryResult;
}

// Create a standard org path from a path and an org code.  Eg convert "/settings"
// to "/org/123/settings".
export function orgPath(path: string, orgExternalCode: string) {
  const cleanPath = path
    // Remove leading slashes so we can pass in either "settings" or "/settings"
    .replace(/^\//, "")

    // If you pass in `/org/123/setting, don't add another /org/123/ - just
    // replace it.
    .replace(/^org\/[\da-z]+\/?/, "");

  return `/org/${orgExternalCode}/${cleanPath}`;
}

function saveCurrentOrgExternalCode(orgExternalCode: string) {
  sessionStorageCache.setItem(LAST_ORG_EXTERNAL_CODE_KEY, orgExternalCode);
  localStorageCache.setItem(LAST_ORG_EXTERNAL_CODE_KEY, orgExternalCode);
}
export function clearCurrentOrgExternalCode() {
  sessionStorageCache.removeItem(LAST_ORG_EXTERNAL_CODE_KEY);
  localStorageCache.removeItem(LAST_ORG_EXTERNAL_CODE_KEY);
}

function orgExternalCodeFromLocalStorage() {
  const orgExternalCode = localStorageCache.getItem(LAST_ORG_EXTERNAL_CODE_KEY);
  return typeof orgExternalCode === "string" ? orgExternalCode : undefined;
}
function orgExternalCodeFromSessionStorage() {
  const orgExternalCode = sessionStorageCache.getItem(
    LAST_ORG_EXTERNAL_CODE_KEY,
  );
  return typeof orgExternalCode === "string" ? orgExternalCode : undefined;
}
export function useOrgExternalCodeFromRoute() {
  return useParams().orgExternalCode;
}
export function useFirstAvailableOrgExternalCode() {
  const { data } = useQuery({
    queryFn: api.getMe,
    queryKey: ["users", "me"],
  });
  return data?.userOrganizations?.[0]?.organization?.externalCode?.toString();
}
