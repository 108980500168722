import { ChartColumn } from "./types";

export const AVAILABLE_COLUMNS: ChartColumn[] = [
  { key: "grossCharge", label: "Gross Charge", color: "#2563eb" },
  { key: "netCharge", label: "Net Charge", color: "#16a34a" },
  { key: "usage", label: "Usage", color: "#9333ea" },
  { key: "credits", label: "Credits", color: "#dc2626" },
  { key: "marketplaceCost", label: "Marketplace Cost", color: "#9a8204" },
  { key: "tax", label: "Tax", color: "#ea580c" },
  {
    key: "savingsPlanUpfrontFee",
    label: "Savings Plan Upfront Fee",
    color: "#0891b2",
  },
  {
    key: "savingsPlanRecurringFee",
    label: "Savings Plan Recurring Fee",
    color: "#db2777",
  },
  { key: "support", label: "Support", color: "#0d9488" },
  { key: "shortfallFees", label: "Shortfall Fees", color: "#ca8a04" },
  {
    key: "serviceUsageCharges",
    label: "Service Usage Charges",
    color: "#4f46e5",
  },
  {
    key: "supplementalCharges",
    label: "Supplemental Charges",
    color: "#65a30d",
  },
  {
    key: "savingsPlanNegation",
    label: "Savings Plan Negation",
    color: "#525252",
  },
  {
    key: "reservedInstanceUpfrontFee",
    label: "Reserved Instance Upfront Fee",
    color: "#1d4ed8",
  },
  {
    key: "reservedInstanceRecurringFee",
    label: "Reserved Instance Recurring Fee",
    color: "#c026d3",
  },
  {
    key: "contractualCrossServiceDiscount",
    label: "Cross Service Discount",
    color: "#1e40af",
  },
  {
    key: "contractualServiceDiscounts",
    label: "Service Discounts",
    color: "#166534",
  },
  { key: "refund", label: "Refund", color: "#991b1b" },
  { key: "savings", label: "Savings", color: "#6b21a8" },
];
