import { Table as MantineTable, Text } from "@mantine/core";
import { flexRender, TableOptions, useReactTable } from "@tanstack/react-table";
import React from "react";
import "./Table.css";

export default function Table<DataType>({
  tanstackTableOptions,
}: {
  tanstackTableOptions: TableOptions<DataType>;
}): React.JSX.Element {
  const table = useReactTable(tanstackTableOptions);

  return (
    <div>
      <MantineTable
        style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
      >
        <MantineTable.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <MantineTable.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <MantineTable.Th
                  key={header.id}
                  style={{ textTransform: "uppercase" }}
                  px="lg"
                  py="xs"
                >
                  <Text c="gray.6" ta="left" fw="bold" fz="sm">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Text>
                </MantineTable.Th>
              ))}
            </MantineTable.Tr>
          ))}
        </MantineTable.Thead>
        <MantineTable.Tbody>
          {table.getRowModel().rows.map((row) => (
            <MantineTable.Tr key={row.id} bg="white" mb={300}>
              {row.getVisibleCells().map((cell) => (
                <MantineTable.Td
                  key={cell.id}
                  className="table-cell"
                  px="lg"
                  py="md"
                  fz="sm"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </MantineTable.Td>
              ))}
            </MantineTable.Tr>
          ))}
        </MantineTable.Tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </MantineTable>
    </div>
  );
}
