import { Box, Flex, Paper, Title } from "@mantine/core";
import LayoutV2 from "../LayoutV2/LayoutV2";

export function NoDataPage(): React.JSX.Element {
  return (
    <LayoutV2 bg="gray.0">
      <Flex justify="center" mih={500} pt={80} h="calc(100vh - 60px)">
        <Paper shadow="md" p="xl" maw={600} lh="sm" mah={200}>
          <Title>No Data Available</Title>
          <Box pt="sm">No data is available yet.</Box>
        </Paper>
      </Flex>
    </LayoutV2>
  );
}
