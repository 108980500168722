import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";

export type DiscountedSpendMonth = {
  month: string;
  discounts: {
    name: string;
    savings: number;
  }[];
};

export type DiscountedSpend = {
  months: DiscountedSpendMonth[];
};

export const DiscountedSpendFactory = Factory.define<DiscountedSpend>(() => ({
  months: [
    {
      month: "2024-01-01",
      discounts: [
        {
          name: "Discount 1",
          savings: 100,
        },
      ],
    },
  ],
}));

export const getDiscountedSpend = ({
  startDate,
  endDate,
  orgExternalCode,
}: {
  startDate: string;
  endDate: string;
  orgExternalCode: string;
}) => {
  return apiRequest.get<DiscountedSpend>(
    `/api/v1/organizations/${orgExternalCode}/discounts/discounted_spend`,
    {
      params: { start_date: startDate, end_date: endDate },
    },
  );
};
