import { AreaChart } from "@mantine/charts";
import { Container, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { DiscountedSpend } from "../../../apiQueries/discounts";
import ErrorScreen from "../../../components/ErrorScreen";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import ScreenLoader from "../../../components/uikit/ScreenLoader";
import { useCurrentOrgExternalCodeWithFallback } from "../../../state/useOrg";

// NOTE: This page is basically throw-away code for testing right now.  Don't
// bother evaluating it for much!

// TODO: expand
const availableColors = ["indigo.6", "blue.6", "teal.6"];

interface DiscountSavingsChartProps {
  discountedSpend: DiscountedSpend;
  availableDiscounts: string[];
}

function DiscountSavingsChart({
  discountedSpend,
  availableDiscounts,
}: DiscountSavingsChartProps): React.JSX.Element {
  const chartData = discountedSpend.months.map((month) => {
    const monthChartData: Record<string, number | string> = {
      date: month.month,
    };
    month.discounts.forEach((discount) => {
      monthChartData[discount.name] = discount.savings * -1;
    });
    return monthChartData;
  });
  const series = availableDiscounts.map((discount, index) => ({
    name: discount,
    color: availableColors[index % availableColors.length],
  }));

  return (
    <AreaChart
      h={300}
      data={chartData}
      dataKey="date"
      type="stacked"
      series={series}
      tooltipAnimationDuration={500}
      withLegend
    />
  );
}

interface EDRChartProps {
  discountedSpend: DiscountedSpend;
  availableDiscounts: string[];
}

function EDRChart({
  discountedSpend,
  availableDiscounts,
}: EDRChartProps): React.JSX.Element {
  const chartData = discountedSpend.months.map((month) => {
    const monthChartData: Record<string, number | string> = {
      date: month.month,
    };
    month.discounts.forEach((discount) => {
      monthChartData[discount.name] = discount.savings * -1;
    });
    return monthChartData;
  });
  const series = availableDiscounts.map((discount, index) => ({
    name: discount,
    color: availableColors[index % availableColors.length],
  }));
  return (
    <AreaChart
      h={300}
      data={chartData}
      dataKey="date"
      type="percent"
      series={series}
      tooltipAnimationDuration={500}
    />
  );
}

export type DiscountsPagePresentationProps = {
  discountedSpend: DiscountedSpend;
};

export function DiscountsPagePresentation({
  discountedSpend,
}: DiscountsPagePresentationProps): React.JSX.Element {
  const availableDiscounts = [
    ...new Set(
      discountedSpend.months.flatMap((month) =>
        month.discounts.map((discount) => discount.name),
      ),
    ),
  ];
  return (
    <LayoutV2>
      <Container p="xl">This page is not yet complete.</Container>
      <Title order={2}>EDR</Title>
      <EDRChart
        discountedSpend={discountedSpend}
        availableDiscounts={availableDiscounts}
      />
      <Title order={2}>Discount savings</Title>
      <DiscountSavingsChart
        discountedSpend={discountedSpend}
        availableDiscounts={availableDiscounts}
      />
    </LayoutV2>
  );
}

export default function DiscountsPage(): React.JSX.Element {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();

  const { data: discountedSpend, error } = useQuery({
    queryKey: ["discounted_spend", orgExternalCode],
    queryFn: () =>
      // TODO: actual date range
      api.getDiscountedSpend({
        startDate: "2024-07-01",
        endDate: "2024-11-01",
        orgExternalCode: orgExternalCode ?? "",
      }),
    enabled: !!orgExternalCode,
  });

  if (error) {
    return <ErrorScreen />;
  }
  if (!discountedSpend || !orgExternalCode) {
    return <ScreenLoader />;
  }

  return <DiscountsPagePresentation discountedSpend={discountedSpend} />;
}
